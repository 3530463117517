<template>
  <div class="contain">
    <div class="rightbox" style="width: 100%">
        <div class="top-lists">
            <div
                    class="nav-view"
                    @click="clickHref(item.path)"
                    :class="navIndex == item.path ? 'styles' : ''"
                    v-for="(item, index) in navList"
                    v-show="item.menuKey!=''"
                    :key="index"
            >
                {{ item.name }}
            </div>
        </div>
      <template>
          <firstPurchase v-if="navIndex==0" />
          <repurchase v-if="navIndex==1" />
          <hignFans v-if="navIndex==2" />
<!--          <seaFans v-if="navIndex==3" />-->
          <sbyd v-if="navIndex==3" />
          <repurchaseTicket v-if="navIndex==4" />
          <markerSpace v-if="navIndex==5" />
          <bonusTotal v-if="navIndex==6"/>
      </template>
        <!--      <div class="topbox">-->
<!--          <el-form  label-width="120px">-->
<!--          <el-row>-->
<!--          <el-col :span="7" >-->
<!--              <el-form-item label="奖金日期" prop="teamCode" style="margin-bottom: 15px">-->
<!--              <el-date-picker-->
<!--              v-model="thetime"-->
<!--              type="daterange"-->
<!--              value-format="yyyy-MM-dd"-->
<!--              range-separator="至"-->
<!--              start-placeholder="开始日期"-->
<!--              end-placeholder="结束日期"-->
<!--            >-->
<!--            </el-date-picker>-->
<!--              </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <div class="searchbox">-->
<!--                <el-button class="btn2">{{ $t('N_I_183') }}</el-button>-->
<!--                <el-button class="btn1">{{ $t('CK_KS_6') }}</el-button>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--          </el-form>-->
<!--      </div>-->
<!--      <div class="tablebox">-->
<!--          <div class="tabletop">-->
<!--              <span class="tabletoptitle1">实发合计：</span>-->
<!--              <span class="tabletoptitle2">{{ money }}</span>-->
<!--          </div>-->
<!--        <el-table-->
<!--          height="700px"-->
<!--          :data="tableData"-->
<!--          :header-cell-style="{ background: '#EEEEEE' }"-->
<!--          :row-class-name="tableRowClassName"-->
<!--        >-->
<!--          &lt;!&ndash; :row-class-name="tableRowClassName" &ndash;&gt;-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="settleDate"-->
<!--            label="奖金日期"-->
<!--          >-->
<!--              <template slot-scope="scope">-->
<!--                  <div @click="dateHref(scope.row)" style="cursor: pointer">{{ scope.row.settleDate}}</div>-->
<!--              </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="directIncome"-->
<!--            :formatter="stateFormat"-->
<!--            label="直推收益"-->
<!--          ></el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="expandIncome"-->
<!--            :formatter="stateFormat"-->
<!--            label="拓展收益"-->
<!--          ></el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="coachIncome"-->
<!--            :formatter="stateFormat"-->
<!--            label="辅导收益"-->
<!--          ></el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="shareIncome"-->
<!--            :formatter="stateFormat"-->
<!--            label="分红收益"-->
<!--          ></el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="repurRangeIncome"-->
<!--            :formatter="stateFormat"-->
<!--            label="复购极差收益"-->
<!--          ></el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="repurExpandIncome"-->
<!--            :formatter="stateFormat"-->
<!--            label="复购拓展收益"-->
<!--          ></el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="serviceIncome"-->
<!--            :formatter="stateFormat"-->
<!--            label="服务补贴"-->
<!--          ></el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="realIncomeTotal"-->
<!--            :formatter="stateFormat"-->
<!--            label="实发金额"-->
<!--          ></el-table-column>-->
<!--&lt;!&ndash;          <el-table-column align="center" prop="" :label="$t('MY_ORD_14')">&ndash;&gt;-->
<!--&lt;!&ndash;            <template slot-scope="scope">&ndash;&gt;-->
<!--&lt;!&ndash;              <el-button style="color:red" @click="goSource(scope.row)" type="text" size="small">查看详情</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;            </template>&ndash;&gt;-->
<!--&lt;!&ndash;          </el-table-column>&ndash;&gt;-->
<!--        </el-table>-->
<!--        <pagination-->
<!--          :total="total"-->
<!--          :page.sync="queryParams.pageNum"-->
<!--          :limit.sync="queryParams.pageSize"-->
<!--          @pagination="research"-->
<!--        />-->
      </div>
    </div>

</template>
  
  <script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import firstPurchase from "@/components/bonus/firstPurchase.vue"; //首购收益
import repurchase from "@/components/bonus/repurchase.vue"; //复购收益
import hignFans from "@/components/bonus/hignFans.vue"; //嗨粉收益
import seaFans from "@/components/bonus/seaFans.vue"; //海粉收益
import sbyd from "@/components/bonus/sbyd.vue"; //圣宝云代收益
import repurchaseTicket from "@/components/bonus/repurchaseTicket.vue"; //复购券收益
import markerSpace from "@/components/bonus/markerSpace.vue"; //创客空间收益
import bonusTotal from "@/components/bonus/bonusTotal.vue"; //创客空间收益
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import { getQueryBonus } from "@/api/bonus.js";
import navBar from "@/components/navBar.vue";
import * as api from "@/api/goods";
export default {
  name: "IncomeDetail",
  components: {
      navBar,
    sidebarUserInfo,
    firstPurchase,
    repurchase,
    hignFans,
    seaFans,
    sbyd,
    repurchaseTicket,
    markerSpace,
    topBanner,
    pagination,
      bonusTotal
  },
  data() {
    return {
        navIndex:0,
      marketList: [
        {
          text: this.$t('ENU_MENU_510'),
          id: 0,
          path: "/marketDynamics",
        },
        {
          text: this.$t('ENU_MENU_520'),
          id: 7,
          path: '/activeZone',
        },
        {
          text: this.$t('ENU_MENU_530'),
          id: 1,
          path: "/incomeDetail",
        },
        {
          text: this.$t('ENU_MENU_540'),
          id: 3,
          path: "/bonusSource",
        },
        // {
        //   text: "云代奖金",
        //   id: 2,
        //   path: "/generation",
        // },

        {
          text: this.$t('ENU_MENU_560'),
          id: 4,
          path: "/appraisal",
        },
        {
          text: this.$t('ENU_MENU_570'),
          id: 5,
          path: "/registration",
        },
        {
          text: this.$t('ENU_MENU_580'),
          id: 6,
          path: "/investment",
        },
      ],
      specialArea: 0,
      thetime: "",
      tableData: [],
      total: 0,
      money:0.00,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        startDate: "",
        endDate: "",
      },
      navList: [
          { name: this.$t('ENU_MENU_531'),path:0,value:"firstPurchase",menuKey:'' },
          // { name: this.$t('ENU_MENU_532'),path:1 ,value:"repurchaseEarnings",menuKey:''},
          // { name: this.$t('ENU_MENU_533'),path:2,value:"hignFans",menuKey:''},
          // { name: this.$t('ENU_MENU_534'),path:3,value:"seaFans",menuKey:''},
          // { name: this.$t('ENU_MENU_535'),path:3,value:"sbyd",menuKey:''},
          // { name: this.$t('ENU_MENU_536'),path:4,value:"repurchaseTicket",menuKey:''},
          // { name: this.$t('ENU_MENU_537'),path:5,value:"markerSpaceEarnings",menuKey:''},
          // { name: this.$t('ENU_MENU_538'),path:6,value:"bonusTotal",menuKey:''},
      ],
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
  },
  created() {},
  mounted() {
    this.specialArea = this.$route.query.id;
    this.getMenuLists()
    // this.research();
    //   let allData = decodeURIComponent(window.location.href).split('=')
    //   this.navIndex =allData[2].split('&')[0]
  },
  methods: {
    getMenuLists(){
        api.menuList().then((res) => {
            res.data.forEach((item)=>{
                this.navList.forEach((items)=>{
                    if(item.menuKey==items.value){
                        items.menuKey = item.menuKey
                    }
                })
            })
        })
    },
    clickHref(index){
        this.navIndex = index
    },
    dateHref(item){
      this.$router.push({
          path:"/bonusSource",
          query: { id: 3,time:item.settleDate},
      });
    },
    goSource(){
      this.$router.push({
        path:"/bonusSource",
        query: { id: 3, },
      });
    },    
    changeArea(item) {
      this.specialArea = item.id;
      //   this.research()
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
    research() {
      if (this.thetime) {
        this.queryParams.startDate = this.thetime[0];
        this.queryParams.endDate = this.thetime[1];
      }
      getQueryBonus(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>
  
  <style lang="scss" scoped>
  .styles {
    border: none !important;
    color: #fff !important;
    background: #d61820;
  }
  .top-lists{
    display: flex;
    padding: 20px 0 0 0;
    flex-wrap: wrap;
    .nav-view{
      //width: 60px;
      cursor: pointer;
      padding: 0 26px;
      height: 44px;
      border-radius: 10px;
      border: 1px solid #999999;
      font-size: 14px;
      text-align: center;
      line-height: 44px;
      margin-right: 20px;
      color: #999;
      margin-bottom: 10px;
    }
  }
  ::v-deep .el-range-editor.el-input__inner{
    width: 100%;
    height: 32px;
    padding: 0 10px;
  }
  ::v-deep .select .el-input--suffix .el-input__inner{
    height: 32px;
  }
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 0!important;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 15px 0 20px 0!important;
    padding: 15px 0 0 0!important;
    .searchbox {
      display: flex;
      align-items: center;
      margin-left: 40px;
      .btn1 {
        border: 1px solid #CCCCCC;
        border-radius: 5px 5px 5px 5px;
        color: #666;
        height: 34px;
        width: 68px;
        padding: 0;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
        height: 34px;
        width: 68px;
        padding: 0;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    .tabletop {
      text-align: right;
      padding: 20px;
      .tabletoptitle1 {
        font-size: 18px;
      }
      .tabletoptitle2 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
</style>